import LegacyStarField from "./images/legacy-starfield.jpg";

type StarFieldArgs = {
    children?: any;
};
export function StarField(args: StarFieldArgs) {
    const styles = {
        background: `url('${LegacyStarField}') top left black`,
        backgroundSize: "cover",
        width: "100vw",
        height: "100vh"
    };
    return (
            <div data-bs-theme="dark" style={styles} className="pt-5 pb-5 ps-5 pe-5">
                {args.children}
            </div>
    );
}