import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, CardBody } from "reactstrap";
import { GameInfo, MowSession } from "./Server";
import { MyContainer } from "./MyContainer";
import { useEffect, useState } from "react";

type HomeArgs = {
    session: MowSession;
    logOut: Function;
};
export function Home(args: HomeArgs) {
    const [games, setGames] = useState<GameInfo[] | null>(null);
    const [open, setOpen] = useState<string>(games?.[0].id.toString() ?? "");
    const toggle = (id: string) => setOpen(open === id ? "" : id);
    useEffect(function() {
        if(games === null)
            args.session.server.getActiveGames().then(games => setGames(games));
    }, []);
    console.log("rendering: %o", games?.length);
    return (
            <MyContainer width={"fit-content"}>
                <Card className="border-secondary text-center bg-transparent">
                    <CardBody>
                        <h1>Welcome, <span>{args.session.username}</span><span>!</span></h1>
                    </CardBody>
                    <CardBody>
                        <Accordion open={open} toggle={toggle}>
                            {(games ?? []).map(function(game) {
                                return (
                                        <AccordionItem>
                                            <AccordionHeader targetId={game.id.toString()}>{game.name}</AccordionHeader>
                                            <AccordionBody accordionId={game.id.toString()} className="text-start">
                                                <span style={{whiteSpace: "pre"}}>{JSON.stringify(game, null, 4)}</span>
                                            </AccordionBody>
                                        </AccordionItem>
                                );
                            })}
                        </Accordion>
                    </CardBody>
                    <CardBody>
                        <Button color="secondary" className="mowSuccess w-auto" onClick={() => args.logOut()}>Log Out</Button>
                    </CardBody>
                </Card>
            </MyContainer>
    );
}