import React, { useState } from "react";
import { Alert, Button, Card, CardBody, CardImg, Container, Form, FormGroup, Input, Label } from "reactstrap";
import FiverLogo from "./images/fiver-logo.png";
import { MowSession, Server } from "./Server";
import { MyContainer } from "./MyContainer";

type LogInErrorArgs = { error: string | null };
function LogInError(args: LogInErrorArgs) {
    if(!args.error)
        return null;
    return (
            <FormGroup>
                <Alert color="danger" className="text-center"> {args.error} </Alert>
            </FormGroup>
    );
}

type LogInArgs = {
    server: Server;
    setSession: ((x: MowSession) => void);
};
export function LogIn(args: LogInArgs) {
    const rememberMeKey = "lastLogInUsername";
    const [username, setUsername] = useState(localStorage.getItem(rememberMeKey) ?? "");
    const [password, setPassword] = useState("");
    const [error, setError] = useState<string | null>(null);

    async function logIn() {
        setError(null);
        if(!username || !password)
            return setError("You must enter a username & password.");
        localStorage.setItem(rememberMeKey, username);
        const response = await args.server.logIn(username, password);
        response instanceof MowSession ? args.setSession(response) : setError(response.message);
    }

    function onSubmit(event: React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        logIn();
    }

    return (
            <MyContainer maxWidth={"500px"}>
                <Card className="border-secondary bg-transparent">
                    <CardBody>
                        <Form onSubmit={onSubmit}>
                            <CardImg src={FiverLogo} className="mb-4"/>
                            <FormGroup> Please sign in to continue </FormGroup>
                            <FormGroup floating={true}>
                                <Input id="username"
                                       type="text"
                                       autoFocus={true}
                                       placeholder="USERNAME"
                                       value={username}
                                       onChange={e => setUsername(e.target.value)}
                                />
                                <Label htmlFor="username">USERNAME</Label>
                            </FormGroup>
                            <FormGroup floating={true} className="mb-5">
                                <Input id="password"
                                       type="password"
                                       placeholder="PASSWORD"
                                       value={password}
                                       onChange={e => setPassword(e.target.value)}
                                       required={true}
                                />
                                <Label htmlFor="password">PASSWORD</Label>
                            </FormGroup>
                            <LogInError error={error}></LogInError>
                            <FormGroup className="text-center">
                                <Button color="secondary" className="mowSuccess w-100">Log In</Button>
                            </FormGroup>
                            <FormGroup className="text-center">
                                <Button color="outline-secondary" className="mowSuccess w-100" disabled={true}>New User Registration</Button>
                            </FormGroup>
                        </Form>
                    </CardBody>
                </Card>
            </MyContainer>
    );
}