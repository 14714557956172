import React, { useEffect, useState } from "react";
import { LogIn } from "./LogIn";
import { MowSession, Server } from "./Server";
import FavPng from "./images/favicon.png";
import { Home } from "./Home";
import { StarField } from "./StarField";

export default function App() {
    const server = new Server();
    const [session, setSession] = useState<MowSession | null | undefined>(undefined);
    useEffect(function() {
        for(const link of document.head.querySelectorAll("link.favIconLink"))
            if(link instanceof HTMLLinkElement)
                link.href = FavPng;
        if(session === undefined)
            server.getCookiedSession().then(existingSession => setSession(existingSession));
    }, [session]);
    if(session === undefined) {
        return (<StarField/>);
    } else if(session === null) {
        return (
                <StarField>
                    <LogIn server={server} setSession={setSession}/>
                </StarField>
        );
    } else {
        function logOut() {
            server.logOut();
            setSession(null);
        }
        return (
                <StarField>
                    <Home session={session} logOut={logOut}></Home>
                </StarField>
        );
    }
}

