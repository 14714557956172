export class MowSession {
    #server: Server;
    #username: string;
    #sessionId: string;
    constructor(server: Server, username: string, sessionId: string) {
        [this.#server, this.#username, this.#sessionId] = [server, username, sessionId];
    }
    get server() { return this.#server; }
    get username() { return this.#username; }
    get sessionId() { return this.#sessionId; }
}

export class AuthError {
    constructor(public message: string) {}
}

export type GameInfo = {
    id: number;
    name: string;
    type: "unranked" | "test" | "ranked" | "event";
    startTime: Date;
    endTime: Date | null;
};

export class Server {
    async getActiveGames(): Promise<GameInfo[]> {
        const response = await fetch("/api/getActiveGames", { method: "post", headers: this.headers });
        const games = await response.json() as GameInfo[];
        console.log(games);
        return games;
    }
    getSessionId(): null | string {
        for(const cookie of document.cookie.split("; ")) {
            const match = /^mowSessionId=(.+)$/.exec(cookie);
            if(match)
                return match[1];
        }
        return null;
    }
    setSessionId(sessionId: string | null) {
        if(sessionId === null)
            document.cookie = `mowSessionId=; expires= Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
        else
            document.cookie = `mowSessionId=${sessionId};`;
    }
    async getCookiedSession(): Promise<MowSession | null> {
        if(this.getSessionId()) {
            const response = await fetch("/api/validateSession", { method: "post", headers: this.headers });
            const { session } = await response.json();
            if(session) {
                const { username, mowSessionId } = session;
                if(typeof username === "string" && typeof mowSessionId === "string")
                    return new MowSession(this, username, mowSessionId);
            }
            this.setSessionId(null);
        } else
            console.log("there is no cookied session ID.");
        return null;
    }
    get headers(): any {
        return {
            "content-type": "application/json",
            mowSessionId: this.getSessionId()
        };
    }
    async logIn(inputUsername: string, password: string): Promise<MowSession | AuthError> {
        const body = JSON.stringify({ username: inputUsername, password });
        const options = { method: "post", headers: this.headers, body };
        const response = await fetch("/api/login", options);
        const json = await response.json();
        const { session, error } = json;
        if(session) {
            const { username, mowSessionId } = session;
            if(typeof username === "string" && typeof mowSessionId === "string") {
                const mowSession = new MowSession(this, username, mowSessionId);
                this.setSessionId(mowSessionId);
                return mowSession;
            }
        }
        return new AuthError(error);
    }
    async logOut() {
        await fetch("/api/logout", { method: "post", headers: this.headers });
        this.setSessionId(null);
    }
}