import { Container } from "reactstrap";

export type MyContainerArgs = {
    width?: string;
    maxWidth?: string;
    children?: any
};

export function MyContainer(args: MyContainerArgs) {
    return (
            <Container className="mt-5" style={args}>
                {args.children}
            </Container>
    );
}